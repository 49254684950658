<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nueva</span>
                    <span v-if="!isNew">Editar</span>
                    elemento
                </div>

              <v-form
                  ref="form"
                  v-model="isValid"
                  autocomplete="off">
              <v-tabs v-model="tab" background-color="transparent" show-arrows>
                  <v-tabs-slider color="blue"></v-tabs-slider>

                  <v-tab href="#tab-elem">Elemento</v-tab>
                  <v-tab href="#tab-cat">Categorías</v-tab>
                  <v-tab href="#tab-emp">Empresas</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab" touchless>
                  <v-tab-item :value="'tab-elem'">
                      <v-card style="background-color: #F5F5F5" flat>
                          <!-- FORMULARIO DIRECCIÓN -->
                          <v-row>
                              <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                                  <v-text-field
                                      class="mb-n7"
                                      outlined
                                      dense
                                      text
                                      v-model="elem.ElemCod"
                                      label="Código">
                                  </v-text-field>
                              </v-col>
                          </v-row>
                        <v-row>
                            <v-col class="px-7 mt-2" cols="12" sm="9" md="6" lg="6" xl="5">
                                <v-text-field
                                    class="mb-n7"
                                    outlined
                                    dense
                                    text
                                    v-model="elem.ElemNom"
                                    required
                                    :rules="[v => !!v || 'El nombre es obligatorio']"
                                    label="Nombre *">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="5">
                                <v-textarea
                                    class="mt-0 mb-n7"
                                    outlined
                                    auto-grow
                                    v-model="elem.ElemObs"
                                    label="Observaciones">
                                </v-textarea>
                            </v-col>
                        </v-row>
                      </v-card>
                  </v-tab-item>
                  
                  <v-tab-item :value="'tab-cat'">
                      <v-card style="background-color: #F5F5F5" flat>
                          <v-row class="mt-0">
                              <!-- CATEGORIAS -->
                              <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                  <!-- Botón añadir categoría -->
                                  <v-btn
                                      :loading="loading"
                                      :disabled="editElemCat"
                                      @click="onBtnNuevaElemCat"
                                      color="primary"
                                      small
                                      class="mb-2">
                                      Añadir categoría
                                  </v-btn>
                                
                                <v-data-table
                                    :headers="elemCatHeaders"
                                    :items="elemCatDataRows"
                                    :options.sync="elemCatOptions"
                                    :footer-props="{itemsPerPageOptions: [100]}"
                                    :server-items-length="elemCatTotalDataRows"
                                    :loading="elemCatLoading"
                                    style="overflow: scroll"
                                    mobile-breakpoint="0"
                                    class="elevation-1"
                                    dense>
                                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                                    <template v-slot:item.Opt="{ item }">
                                        <v-btn
                                            color="error"
                                            class="mr-2 my-1"
                                            small
                                            @click.stop="onBtnShowElemCatDeleteModal(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <!-- NUEVA CATEGORÍA FORMULARIO -->
                                    <template v-if="editElemCat" slot="body.append">
                                        <tr class="new-row">
                                            <td>
                                                <ElemCatFind ref="elemCatCtrl" v-model="newElemCat" :elemCatToHide="elemCatDataRows" label="Categoría" :hideDetail="true"></ElemCatFind>
                                            </td>
                                            <td style="min-width: 250px" align="right">
                                                <v-btn
                                                :loading="elemCatLoading"
                                                :disabled="newElemCat == null"
                                                @click="onBtnSaveElemCat"
                                                color="primary"
                                                class="mt-2 mb-4 mr-2"
                                                small>
                                                Aceptar
                                                </v-btn>
                                                
                                                <v-btn
                                                @click="onBtnCancelElemCat"
                                                class="mt-2 mb-4 mr-2"
                                                small>
                                                Cancelar
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-tab-item>
                
                  <v-tab-item :value="'tab-emp'" eager>
                      <v-card style="background-color: #F5F5F5" flat>
                          <!-- EMPRESAS -->
                          <v-row class="mt-0">
                              <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                <EmpresasRelacionadas
                                    ref="relatedEmps"
                                    :empList="empDataList" 
                                    :empTotal="empDataTotal" 
                                    :loading="loading" 
                                    @update-data="updateEmpData">
                                </EmpresasRelacionadas>
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-tab-item>
              </v-tabs-items>

              <!-- ACEPTAR / CANCELAR / BORRAR -->
              <v-row class="mt-2" justify="center">
                  <v-btn
                      :loading="loading"
                      :disabled="!isValid"
                      @click="onBtnSave"
                      color="primary"
                      class="mt-2 mb-4 mr-2"
                      style="width:100px">
                      Aceptar
                  </v-btn>
                  
                  <v-btn
                      class="mt-2 mb-4 mr-2"
                      style="width:100px"
                      @click="goToList">
                      Cancelar
                  </v-btn>

                  <!-- && canDelete" -->
                  <v-btn v-if="!isNew"
                    color="error"
                    class="mt-2 mb-4"
                    style="width:100px"
                    @click="onBtnShowDeleteModal()">
                      Borrar
                  </v-btn>
              </v-row>
              </v-form>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar elemento -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR ELEMENTO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el elemento? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar Categoria -->
        <v-dialog
            v-model="showElemCatDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR CATEGORÍA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la categoría?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="elemCatLoading"
                        color="primary"
                        @click="onBtnElemCatDelete">
                        Aceptar
                    </v-btn>
                    <v-btn
                        @click="onBtnCancelElemCatDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ElemCatFind from "../components/ElemCatFind.vue";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
export default ({
    components: {
        ElemCatFind, EmpresasRelacionadas
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            showElemCatDeleteDialog: false,
            elem: {
                ElemId: 0,
                ElemCod: '',
                ElemNom: '',
                ElemObs: ''
            },
            // Tabla categorias
            elemCatLoading: false,
            elemCatTotalDataRows: 0,
            elemCatDataRows: [],
            elemCatOptions: {itemsPerPage: 100},
            elemCatHeaders: [
                { text: 'Categorías relacionadas', width:300, sortable: false, value: 'ElemCatNom' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: [],
            // Editar categorias
            editElemCat: false, // Indica si se esta editando la nueva categoria
            newElemCat: {},
            selectedElemCat: null // Indica la categoria seleccionada para el borrado
        }
    },
    // watch: {
    //   elem: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.elem.ElemId = this.$route.params.id;
        this.loadData();
      } else {
        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/elementos');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_ELEM',
            ElemId: this.elem.ElemId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/elem", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Elem;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el elemento.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        const empIds = this.empDataList.map(m => m.EmpId);
        const elemCatIds = this.elemCatDataRows.map(m => m.ElemCatId);

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            Elem: this.elem,
            EmpIds: empIds,
            ElemCatIds: elemCatIds
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/elem", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el elemento.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            ElemId: this.elem.ElemId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/elem", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar el elemento.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos  
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Elemento no encontrado.');
              this.goToList();
              return;
            }

            this.elem = data.item;
            
            this.empDataTotal = data.item.ElemEmp.length;
            this.empDataList = [];
            data.item.ElemEmp.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });
            
            this.elemCatTotalDataRows = data.item.ElemCatElem.length;
            this.elemCatDataRows = [];
            data.item.ElemCatElem.forEach(i => {
              this.elemCatDataRows.push({
                ElemCatId: i.ElemCat.ElemCatId,
                ElemCatNom: i.ElemCat.ElemCatNom
              });
            });
          });
      },
      //#endregion

      //#region Añadir categorías
      onBtnNuevaElemCat() {
        this.editElemCat = true;
        this.newElemCat = null;
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.ElemId != null) {
              this.goToList();
            }
            else {
              alert('Error al guardar el elemento.');
            }
          });
      },
      onBtnSaveElemCat() {
        if(this.newElemCat == null) { return; }
        this.elemCatDataRows.push(this.newElemCat);
        this.elemCatTotalDataRows += 1;
        this.newElemCat = null,
        this.editElemCat = false; 
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Cancelar creación de categoría
      onBtnCancelElemCat() {
        this.editElemCat = false;
      },
      //#endregion

      //#region Eliminar categorías
      removeElemCat(item) {
        var index = this.elemCatDataRows.map(m => m.ElemCatId).indexOf(item.ElemCatId);
        if (index != -1) {
          this.elemCatDataRows.splice(index, 1);
          this.elemCatTotalDataRows -= 1;  
        }
      },
      onBtnElemCatDelete() {
        this.removeElemCat(this.selectedElemCat);
        this.selectedElemCat = null;
        this.showElemCatDeleteDialog = false;
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowElemCatDeleteModal(item) {
        this.selectedElemCat = item;
        this.showElemCatDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelElemCatDelete() {
        this.selectedElemCat = null;
        this.showElemCatDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      //#endregion

      //#region Validar formulario
      validate() {
        this.$refs.form.validate();
      }
      //#endregion
    } 
})
</script>
